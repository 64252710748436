const xxxxs = 1; // -1
const xxxs = 2; // -1
const xxs = 3; // -3
const xs = 6; // -3
const s = 12; // -6
const m = 18; // 0
const l = 24; // +6
const xl = 36; // +12
const xxl = 48; // +12
const xxxl = 72; // +24
const xxxxl = 96; // +24

const sizes = { xxxxs, xxxs, xxs, xs, s, m, l, xl, xxl, xxxl, xxxxl };

const len = (size) => sizes[size];

const Icon = ({
  children,
  color = "var(--text-color)",
  hover,
  size = "l",
  onClick = null,
}) => (
  <>
    <style jsx>{`
      .icon {
        display: inline;
        fill: none;
        vertical-align: bottom;
        --color: ${color};
      }
      .icon:hover {
        --color: ${hover ? hover : color};
      }
    `}</style>
    <svg
      className="icon"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      width={len(size)}
      height={len(size)}
      onClick={onClick}
    >
      {children ? (
        children
      ) : (
        <rect
          x="3"
          y="3"
          width="18"
          height="18"
          rx="3"
          ry="3"
          style={{ fill: "var(--color)" }}
        />
      )}
    </svg>
  </>
);

export default Icon;
