import Image from "next/image";
import SubscribeForm from "../components/SubscribeForm";

const HeroSection = () => (
  <>
    <style jsx>{`
      .hero-section {
        column-gap: var(--gutter-width);
        display: grid;
        font: var(--font-hero);
        justify-content: center;
        padding: 0 var(--gutter-width);
        row-gap: var(--size-xxxl);
        text-align: center;
      }
      .message {
        align-content: center;
        display: grid;
        row-gap: var(--size-s);
        grid-auto-rows: max-content;
        grid-column: 1 / -1;
      }
      .title {
        font: var(--font-title);
      }
      .strike-through > span {
        text-decoration: line-through;
      }
      .strike-through::after {
        content: " graph";
      }
      .subscribe-form {
        text-align: left;
      }
      .image-narrow {
        grid-column: 1 / -1;
      }
      .image-wide {
        display: none;
        grid-column: 1 / -1;
      }
      @media (min-width: 504px) {
        .hero-section {
          grid-template-columns: repeat(4, var(--column-width));
        }
      }
      @media (min-width: 744px) {
        .hero-section {
          grid-template-columns: repeat(6, var(--column-width));
        }
        .message {
          grid-column: 2 / -2;
        }
        .image-narrow {
          display: none;
        }
        .image-wide {
          display: block;
        }
      }
      @media (min-width: 956px) {
        .hero-section {
          grid-template-columns: repeat(8, var(--column-width));
        }
        .message {
          grid-column: 1 / 5;
        }
        .image-narrow {
          display: block;
          grid-column: 5 / -1;
        }
        .image-wide {
          display: none;
        }
      }
      @media (min-width: 1224px) {
        .hero-section {
          grid-template-columns: repeat(10, var(--column-width));
        }
        .image-narrow {
          display: none;
        }
        .image-wide {
          display: block;
          grid-column: 5 / -1;
        }
      }
    `}</style>
    <section className="hero-section">
      <div className="message">
        <h1 className="title">
          Make your team more efficient with a modern knowledge{" "}
          <span className="strike-through">
            <span>base</span>
          </span>
        </h1>
        <p>
          ColonyDB makes it easier for your team to share ideas and document
          institutional knowledge. Spend less time stuck in information sharing
          meetings and more time making your ideas a reality.
        </p>
        <div className="subscribe-form">
          <SubscribeForm />
        </div>
      </div>
      <div className="image-narrow">
        <Image
          src="/hero-narrow.svg"
          alt=""
          layout="responsive"
          width={456}
          height={684}
          priority
        />
      </div>
      <div className="image-wide">
        <Image
          src="/hero-wide.svg"
          alt=""
          layout="responsive"
          width={696}
          height={784}
        />
      </div>
    </section>
  </>
);

export default HeroSection;
