import { useEffect, useRef, useState } from "react";

import Image from "next/image";

const AnimatedPath = ({ d, triggerRef, seq = 0 }) => {
  const ref = useRef(null);
  const [status, setStatus] = useState("init");
  const [length, setLength] = useState(undefined);
  useEffect(() => {
    setLength(ref.current.getTotalLength());
    setStatus("ready");

    const intersectionObserver = new IntersectionObserver(
      (entries) => {
        for (let entry of entries) {
          if (entry.intersectionRatio >= 1) {
            intersectionObserver.unobserve(entry.target);
            setTimeout(() => {
              setStatus("animating");
            }, seq * 1000);
          }
        }
      },
      {
        threshold: 1,
      }
    );

    if (triggerRef) {
      intersectionObserver.observe(triggerRef.current);
    }

    return () => {
      intersectionObserver.disconnect();
    };
  }, []);
  return (
    <path
      ref={ref}
      style={
        status === "animating"
          ? {
              transition: `stroke-dashoffset ${
                window.matchMedia("(prefers-reduced-motion: reduce)").matches
                  ? "0s"
                  : "1s"
              }`,
            }
          : null
      }
      strokeDasharray={status !== "init" ? [length, length] : null}
      strokeDashoffset={status === "animating" ? 0 : length}
      vectorEffect="non-scaling-stroke"
      d={d}
    />
  );
};

const AboutSection = () => {
  const featureA = useRef(null);
  const featureB = useRef(null);
  const featureC = useRef(null);
  return (
    <>
      <style jsx>{`
        .features-section {
          --background-color: var(--color-froth);
        }
        @media (prefers-color-scheme: dark) {
          .features-section {
            --background-color: var(--color-midnight);
          }
        }
        .features-section {
          background: var(--background-color);
          column-gap: var(--gutter-width);
          display: grid;
          grid-auto-flow: row;
          justify-content: center;
          padding: var(--size-xxxl) var(--gutter-width);
          row-gap: var(--size-xxl);
        }
        .feature {
          display: grid;
          column-gap: var(--gutter-width);
          grid-column: 1 / -1;
          grid-auto-flow: row dense;
          row-gap: var(--size-xxl);
        }
        .feature-a {
          grid-row: 1 / 2;
        }
        .feature-b {
          grid-row: 2 / 3;
        }
        .feature-c {
          grid-row: 3 / 4;
        }
        .description {
          align-content: center;
          display: grid;
          font: var(--font-hero);
          gap: var(--size-m);
          grid-auto-rows: max-content;
          grid-column: 1 / -1;
          text-align: center;
        }
        .title {
          font: var(--font-title);
        }
        .image {
          grid-column: 1 / -1;
        }
        .annotations {
          grid-area: 1 / 1 / 4 / -1;
          position: relative;
          pointer-events: none;
          display: none;
        }
        .annotations-svg {
          stroke: var(--accent-color);
          width: 100%;
        }
        .annotation-a {
          transform: translate(46px, 352px);
        }
        .annotation-b {
          transform: translate(366px, 414px);
        }
        .annotation-c {
          transform: translate(990px, 708px);
        }
        .annotation-d {
          transform: translate(510px, 793px);
        }
        .annotation-e {
          transform: translate(227px, 875px);
        }
        .annotation-f {
          transform: translate(152px, 1215px);
        }
        .annotation-g {
          transform: translate(341px, 934px);
        }
        .annotation-h {
          transform: translate(267px, 1344px);
        }
        @media (min-width: 504px) {
          .features-section {
            grid-template-columns: repeat(4, var(--column-width));
          }
        }
        @media (min-width: 744px) {
          .features-section,
          .feature {
            grid-template-columns: repeat(6, var(--column-width));
          }
          .description {
            grid-column: 2 / -2;
          }
        }
        @media (min-width: 956px) {
          .features-section,
          .feature {
            grid-template-columns: repeat(8, var(--column-width));
          }
          .features-section {
            grid-template-rows: repeat(3, 384px);
          }
          .description {
            grid-column-start: auto;
            grid-column-end: span 3;
          }
          .feature-a .description,
          .feature-c .description {
            grid-column-start: 6;
          }
          .image {
            grid-column-start: auto;
            grid-column-end: span 5;
          }
          .annotations {
            display: block;
          }
          .annotation-a {
            transform: translate(39px, 292px) scale(0.828, 0.828);
          }
          .annotation-b {
            transform: translate(248px, 342px) scale(0.821, 0.821);
          }
          .annotation-c {
            transform: translate(766px, 589px) scale(0.928, 0.928);
          }
          .annotation-d {
            transform: translate(382px, 658px) scale(0.84, 0.84);
          }
          .annotation-e {
            transform: translate(212px, 780px) scale(0.628, 0.7);
          }
          .annotation-f {
            transform: translate(121px, 1013px) scale(0.955, 0.955);
          }
          .annotation-g {
            transform: translate(284px, 808px) scale(0.728, 0.728);
          }
          .annotation-h {
            transform: translate(207px, 1115px) scale(0.928, 0.928);
          }
        }
        @media (min-width: 1224px) {
          .features-section,
          .feature {
            grid-template-columns: repeat(10, var(--column-width));
          }
          .features-section {
            grid-template-rows: repeat(3, 464px);
          }
          .description {
            grid-column-end: span 4;
          }
          .feature-a .description,
          .feature-c .description {
            grid-column-start: 7;
          }
          .image {
            grid-column-end: span 6;
          }
          .annotation-a {
            transform: translate(46px, 349px) scale(1, 1);
          }
          .annotation-b {
            transform: translate(350px, 414px) scale(1, 1);
          }
          .annotation-c {
            transform: translate(980px, 708px) scale(1, 1);
          }
          .annotation-d {
            transform: translate(508px, 785px) scale(1, 1);
          }
          .annotation-e {
            transform: translate(227px, 875px) scale(1, 1);
          }
          .annotation-f {
            transform: translate(152px, 1215px) scale(1, 1);
          }
          .annotation-g {
            transform: translate(341px, 934px) scale(1, 1);
          }
          .annotation-h {
            transform: translate(263px, 1344px) scale(1, 1);
          }
        }
      `}</style>
      <section className="features-section">
        <div ref={featureA} className="feature feature-a">
          <div className="description">
            <h1 className="title">
              Visualizations to convey complex ideas succinctly
            </h1>
            <p>
              {/* ColonyDB has powerful visualizations for a wide variety of use
            cases. Focus on getting your ideas right instead of worrying about
            how to present them. */}
              ColonyDB has powerful visualizations for a wide variety of use
              cases. Focus on getting your ideas right, not how to present them.
            </p>
          </div>
          <div className="image">
            <Image
              src="/feature-a.svg"
              alt=""
              width={696}
              height={464}
              layout="responsive"
            />
          </div>
        </div>
        <div ref={featureB} className="feature feature-b">
          <div className="description">
            <h1 className="title">
              Bidirectional links to connect related information
            </h1>
            <p>
              {/* The knowledge graph at the core of ColonyDB connects related
            information making it easy to explore. Get the context you need to
            see how your work fits into the bigger picture. */}
              The knowledge graph at the core of ColonyDB makes exploration
              easy. Get the context you need to see how your work fits into the
              bigger picture.
            </p>
          </div>
          <div className="image">
            <Image
              src="/feature-b.svg"
              alt=""
              width={696}
              height={464}
              layout="responsive"
            />
          </div>
        </div>
        <div ref={featureC} className="feature feature-c">
          <div className="description">
            <h1 className="title">Rules to automate documentation updates</h1>
            <p>
              {/* Use ColonyDB's rules to keep important details synchronized
            throughout your documentation. Create documentation that is always
            relevant and get notified when things change. */}
              Use ColonyDB's rules to keep important details synchronized.
              Create documentation that is always relevant and get notified when
              things change.
            </p>
          </div>
          <div className="image">
            <Image
              src="/feature-c.svg"
              alt=""
              width={696}
              height={464}
              layout="responsive"
            />
          </div>
        </div>
        <div className="annotations">
          <svg
            className="annotations-svg"
            width={1176}
            height={1488}
            fill="none"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g className="annotation-a">
              <AnimatedPath
                triggerRef={featureA}
                d="M596 13.798C573.515-2.468 479.585 4.47 291.711 4.47 239.376 4.47 15.396.41 3.404 24.04c-6.2 12.215 60.465 15.846 90.94 16.983 98.933 3.692 147.584 4.8 283.808 4.8C477 45.822 544.5 48.298 584 39.298c18.377-4.187 22.5-19 12-31.5"
              />
            </g>
            <g className="annotation-b">
              <AnimatedPath
                triggerRef={featureA}
                seq={1}
                d="M3 3c91 135 263 124.941 396 135 133 10.059 291 37 326 148"
              />
            </g>
            <g className="annotation-c">
              <AnimatedPath
                triggerRef={featureA}
                seq={2}
                d="M172.499 14C148.999-3.5 38.799-.98 13 24-2 38.524-3.818 65.968 37.5 68 68 69.5 90 70.5 138 66c39.536-3.706 53.059-36.617 19-52"
              />
            </g>
            <g className="annotation-d">
              <AnimatedPath
                triggerRef={featureA}
                seq={3}
                d="M145.872 68.151c4.33-53.5-49.5-74.267-89-61.5-49.5 16-67.5 76.5-43 123 26.816 50.894 100 31.5 121 4S153.5 63.5 152 57"
              />
            </g>
            <g className="annotation-e">
              <AnimatedPath
                triggerRef={featureA}
                seq={4}
                d="M271.5 3C183.5 71 37 223.5 3 329"
              />
            </g>
            <g className="annotation-f">
              <AnimatedPath
                triggerRef={featureA}
                seq={5}
                d="M99 25C87.5 7 67.85 1.346 52.5 3.5 24 7.5 6.64 23.578 3 54.5-.44 83.723 17 106 41 110s58.309 1.449 70-35c8.5-26.5 3-53-12-63.5"
              />
            </g>
            <g className="annotation-g">
              <AnimatedPath
                triggerRef={featureA}
                seq={6}
                d="M174 3C87.605 121.566 21.915 249.977 3 397"
              />
            </g>
            <g className="annotation-h">
              <AnimatedPath
                triggerRef={featureA}
                seq={7}
                d="M113 28.5C85.5-3 50.739-2.814 23.732 13.69-.5 28.5-5.5 77.5 20 118.5c21.729 34.936 84.905 23.834 97.5 9 22.5-26.5 17-84-8.768-106.92"
              />
            </g>
          </svg>
        </div>
      </section>
    </>
  );
};

export default AboutSection;
