const Action = ({ icon: Icon = null, label, url, external = false }) => (
  <>
    <style jsx>{`
      .action {
        cursor: pointer;
        display: inline-grid;
        grid-auto-flow: column;
        align-items: center;
        font: var(--font-annotation);
        text-transform: uppercase;
        grid-auto-columns: max-content;
      }
      .label {
        color: var(--text-color);
        transition: color 0.2s;
      }
      .action:focus .label,
      .action:hover .label {
        --text-color: var(--accent-color);
      }
    `}</style>
    <a className="action" href={url} target={external ? "_blank" : "_self"}>
      {Icon ? <Icon /> : null}
      <span className="label">{label}</span>
    </a>
  </>
);

export default Action;
