import AboutSection from "../sections/AboutSection";
import Container from "../components/Container";
import FeaturesSection from "../sections/FeaturesSection";
import Footer from "../components/Footer";
import Header from "../components/Header";
import HeroSection from "../sections/HeroSection";
import Metadata from "../components/Metadata";

const Index = () => (
  <>
    <Metadata
      title="ColonyDB"
      description="ColonyDB makes it easier for your team to share information. Create Gantt charts, org charts, project plans and cashflow projections your entire team will embrace."
      image="https://colonydb.com/colonydb-640x640.png"
      canonical="https://colonydb.com/"
      twitter="@colonydb"
      orgUrl="https://colonydb.com/"
      orgLogo="https://colonydb.com/colonydb-640x640.png"
      icon="https://colonydb.com/logo-32w.png"
    />
    <Container>
      <Header />
      <HeroSection />
      <AboutSection />
      <FeaturesSection />
      <Footer />
    </Container>
  </>
);

export default Index;
